function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) return '';
  return (
    string[0].toUpperCase() +
    string
      .split('')
      .splice(1, string.length - 1)
      .join('')
      .toLowerCase()
  );
}

function capitalizeWordsFirstLetter(inputString) {
  if (!inputString || typeof inputString !== 'string' || inputString.length === 0) return '';
  const words = inputString.split(' ');
  const capitalizedWords = words.map((word) => {
    if (!word) return '';
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
}

function removeNonAlphanumericalCharacters(inputString) {
  if (typeof inputString !== 'string') {
    return '';
  }
  return inputString.replace(/[^a-zA-Z0-9]/g, '');
}

function getStringBetweenTwoSubstrings(mainString, firstString, secondString) {
  if (!mainString || !firstString || !secondString) {
    return '';
  }

  const firstIndex = mainString.indexOf(firstString);
  if (firstIndex >= 0) {
    const secondIndex = mainString.indexOf(secondString, firstIndex + firstString.length);
    return mainString.substring(firstIndex + firstString.length, secondIndex);
  }
  return '';
}

module.exports = {
  capitalizeFirstLetter,
  capitalizeWordsFirstLetter,
  removeNonAlphanumericalCharacters,
  getStringBetweenTwoSubstrings,
};
